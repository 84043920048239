
<template>
  <div>
    <div class="border-b py-2 w-2/3">
      <p class="font-semibold">Status do pedido</p>

      <div class="grid grid-cols-4 space-x-1 my-3">
        <div>
          <div
            class="box"
            :class="{
              loading: statusPedidoAtual === 'pending',
              statusChecked: pending && statusPedidoAtual !== 'pending',
              notCompleted:
                statusPedidoAtual === 'canceled' ||
                statusPedidoAtual === 'rejected',
            }"
          ></div>
        </div>
        <div>
          <div
            class="box"
            :class="{
              loading: statusPedidoAtual === 'in-preparation',
              statusChecked:
                inPreparation && statusPedidoAtual !== 'in-preparation',
              notCompleted:
                statusPedidoAtual === 'canceled' ||
                statusPedidoAtual === 'rejected',
            }"
          ></div>
        </div>
        <div>
          <div
            class="box"
            :class="{
              loading: statusPedidoAtual === 'delivery',
              statusChecked: delivery && statusPedidoAtual !== 'delivery',
              notCompleted:
                statusPedidoAtual === 'canceled' ||
                statusPedidoAtual === 'rejected',
            }"
          ></div>
        </div>
        <div>
          <div
            class="box"
            :class="{
              statusChecked: statusPedidoAtual === 'concluded',
              notCompleted:
                statusPedidoAtual === 'canceled' ||
                statusPedidoAtual === 'rejected',
            }"
          ></div>
        </div>
      </div>

      <div
        v-if="statusPedidoAtual === 'pending'"
        class="flex items-center space-x-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-yellow-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span class="text-sm text-gray-700"
          >Aguardando a confirmação do restaurante</span
        >
      </div>

      <div
        v-if="statusPedidoAtual === 'in-preparation'"
        class="flex items-center space-x-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-yellow-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span class="text-sm text-gray-700"
          >Restaurante já começou a preparar seu pedido</span
        >
      </div>
      <div
        v-if="statusPedidoAtual === 'delivery'"
        class="flex items-center space-x-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-blue-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <span class="text-sm text-gray-700">Seu pedido está indo até você</span>
      </div>
      <div
        v-if="statusPedidoAtual === 'concluded'"
        class="flex items-center space-x-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-green-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
        <span class="text-sm text-gray-700"
          >Concluído: Seu pedido foi entregue</span
        >
      </div>
      <div
        v-if="
          statusPedidoAtual === 'canceled' || statusPedidoAtual === 'rejected'
        "
        class="flex items-center space-x-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-red-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <span class="text-sm text-gray-700"> Seu pedido foi cancelado</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],

  data() {
    return {
      statusPedido: this.order.status,
      tempoParaConsulta: 6000,

      pending: false,
      inPreparation: false,
      delivery: false,
      concluded: false,
    };
  },
  methods: {
    ...mapActions(["getOrderIdentify"]),

    getStatusOrderIdentify() {
      if (this.statusPedido === "pending") {
        this.pending = true;
      }
      if (this.statusPedido === "in-preparation") {
        this.tempoParaConsulta = 60000; // 1 minuto
        this.pending = true;
        this.inPreparation = true;
      }
      if (this.statusPedido === "delivery") {
        this.pending = true;
        this.inPreparation = true;
        this.delivery = true;
      }
      if (this.statusPedido === "concluded") {
        this.pending = true;
        this.inPreparation = true;
        this.delivery = true;
        this.concluded = true;
      }
    },
  },

  computed: {
    statusPedidoAtual() {
      return this.statusPedido;
    },
  },

  mounted() {
    this.getStatusOrderIdentify();

    if (
      this.statusPedido !== "concluded" &&
      this.statusPedido !== "canceled" &&
      this.statusPedido !== "rejected"
    ) {
      setInterval(() => {
        this.getOrderIdentify(this.order.identify).then((response) => {
          this.statusPedido = response.data.data.status
          if (
            response.data.data.status === "concluded" ||
            response.data.data.status === "canceled" ||
            response.data.data.status === "rejected"
          ) {
            setTimeout(() => {
              location.reload();
            }, 10000);
          }

          this.getStatusOrderIdentify();
        });
      }, this.tempoParaConsulta);
    }
  },
};
</script>



<style  lang="postcss" scoped>

.progress-container {
  height: 0.7rem;
  width: 90%;
  border-radius: 0.3rem;
  background: rgba(43, 42, 42, 0.082);
}

.progress-container .progress {
  height: 100%;
  width: 0;
  border-radius: 0.3rem;
  /* transition: width 0.4s ease; */
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  to {
    width: 100%;
  }
}
.statusChecked {
  @apply bg-green-400 !important;
}
.notCompleted {
  @apply bg-red-400 !important;
}
.box {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 6px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}
.loading {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 6px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.loading:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  animation: load 5s infinite;
}

@keyframes load {
  0% {
    width: 0;
    /* background: #a28089; */
    /* background: #494d5f; */
    @apply bg-green-400;
  }

  /* 25% {
    width: 40%;
    background: #a0d2eb;
  } */

  /* 50% {
    width: 60%;
    background: #ffa8b6;
  } */

  /* 75% {
    width: 75%;
    background: #d0bdf4;
  }  */

  100% {
    width: 100%;
    @apply bg-green-300;
  }
}
</style>
