<template>
  <div class="my-5 px-4 md:px-0">
    <router-link
      v-if="!loading"
      :to="{ name: 'my.orders' }"
      class="flex items-center text-gray-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
          clip-rule="evenodd"
        />
      </svg>
      Voltar
    </router-link>

    <div v-if="order.identify">
      <section class="grid gap-x-72 sm:grid-cols-1 md:grid-cols-2 mt-10 px-2">
        <div class="">
          <div>
            <h1 class="text-lg md:text-4xl md:my-4 font-semibold">
              {{ order.company.name }}
            </h1>

            <div>
              <LoadingOrderStatus :order="order" />
            </div>

            <div>
              <div class="border-b py-2 md:my-10 text-sm">
                <p class="font-semibold">Nº do pedido</p>
                <p>{{ order.identify }}</p>
                <p class="font-semibold">Data do pedido</p>
                <p class="">{{ order.date }}</p>
              </div>

              <div class="border-b py-2 text-sm">
                <div v-if="order.delivery">
                  <p class="font-semibold">Entrega em:</p>
                  <p class="">
                    {{ order.deliveryPlace.street }},
                    {{ order.deliveryPlace.number }}
                    <span v-if="order.deliveryPlace.complement">
                      {{ order.deliveryPlace.complement }}
                    </span>
                  </p>
                  <p class="">
                    {{ order.deliveryPlace.neighborhood }} -
                    {{ order.deliveryPlace.city.title }}/{{
                      order.deliveryPlace.state.letter
                    }}
                  </p>
                  <p class="flex">
                    Ponto de referência:
                    <span v-if="order.deliveryPlace.reference_point">
                      {{ order.deliveryPlace.reference_point }}</span
                    >
                    <span v-else>Não informado</span>
                  </p>
                </div>

                <div v-else>
                  <p>Retirar em:</p>
                  <p class="">
                    {{ order.company.street }},
                    {{ order.company.number }}
                    <span v-if="order.company.complement">
                      {{ order.company.complement }}
                    </span>
                  </p>
                  <p>{{ order.company.neighborhood }}, SP - Quatá/SP</p>
                  <p class="">{{ order.company.telephone }}</p>
                  <p class="">{{ order.company.cell_phone }}</p>
                </div>
              </div>

              <!---Tempo de entrega--->
              <div class="py-2">
                <p v-if="order.delivery" class="font-semibold">
                  Tempo para entrega:
                </p>
                <p v-else class="font-semibold">Tempo para retirada:</p>
                <span
                  class="text-gray-800 text-sm"
                  v-if="order.detail[0].timeForWithdrawOrDelivery"
                >
                  {{ order.detail[0].timeForWithdrawOrDelivery }} min</span
                >
                <span v-else class="text-gray-800 text-sm"
                  >Não informado pela loja</span
                >
              </div>

              <div class="border-b py-2">
                <p class="font-semibold">Pagamento</p>

                <p v-if="order.payment === 0">Dinheiro</p>

                <div class="flex space-x-2" v-if="order.payment === 1">
                  <p >
                    Cartão {{ order.detail[0].payment.description }}
                  </p>
                  <img
                    :src="order.detail[0].payment.image"
                    :alt="order.detail[0].payment.description"
                    class="h-5"
                  />
                </div>
                <div class="flex space-x-2" v-if="order.payment === 2">
                  <p >
                    Pix para: {{ order.detail[0].payment.pivot.numberPix }}
                  </p>
                  <img
                    :src="order.detail[0].payment.image"
                    :alt="order.detail[0].payment.description"
                    class="h-5"
                  />
                </div>
              </div>
            </div>
            <div class="border-b py-2">
              <p class="font-semibold mb-1">Contato da loja</p>

              <div class="flex text-sm" v-if="order.company.telephone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                {{ order.company.telephone }}
              </div>
              <div class="flex text-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                {{ order.company.cell_phone }}
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="my-10 grid gap-4 sm:grid-cols-1 md:grid-cols-1 md:p-0">
            <div v-for="(item, index) in order.detail" :key="index">
              <div
                class="
                  flex
                  items-baseline
                  justify-between
                  py-3
                  border-b
                  text-sm
                "
              >
                <div>
                  <p>{{ item.qty }} x {{ item.name }}</p>
                  <div
                    v-for="(complement, index) in item.complements"
                    :key="index"
                  >
                    <p class="m-0">{{ complement.name }}</p>
                    <span
                      v-for="(optional, index) in complement.optionals"
                      :key="index"
                    >
                      {{ optional.name }} R$ {{ priceFormat(optional.price) }}
                    </span>
                  </div>
                  <span class="block text-gray-500 text-xs">{{
                    item.comment
                  }}</span>
                </div>
                <p>
                  <span> R$ {{ priceFormat(item.price) }} </span> -
                  <span>
                    R$ {{ priceFormat(generateTotalItemPrice(item)) }}</span
                  >
                </p>
              </div>
            </div>
            <div>
              <div class="flex items-center justify-between text-sm">
                <p>SubTotal</p>
                <p class="">{{ order.subTotal }}</p>
              </div>
              <div class="flex items-center justify-between text-sm">
                <p>Taxa de entrega</p>
                <p class="">{{ order.deliveryFee }}</p>
              </div>
              <div class="flex items-center justify-between text-sm">
                <p>Total</p>
                <p class="">{{ order.total }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5" v-if="order.evaluations === null">
          <button
            class="bg-green-400 p-3 rounded text-white"
            @click.prevent="openModalEvaluation"
          >
            Avaliar o pedido
          </button>
        </div>
      </section>

      <section v-if="order.evaluations" class="my-5">
        <div>
          <div class="space-x-2">
            <span>Cliente:</span> <span>{{ order.client.name }}</span>
          </div>
          <div class="space-x-2">
            <span>Comentário:</span>
            <span>{{ order.evaluations.comment }}</span>
          </div>
          <div class="flex space-x-3">
            <span>Nota: </span>
            <StarRating
              :increment="0.5"
              :star-size="16"
              :read-only="true"
              :round-start-rating="false"
              :rating="order.evaluations.stars"
              text-class=" text-sm text-gray-700 mt-1"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else-if="!loading">
      <p>Tivemos um problema por aqui</p>
      <p>
        Por favor, aguarde um momento e tente outra vez para ver os detalhes do
        seu pedido
      </p>
    </div>

    <!-- modal pra avaliar pedido-->
    <modal name="evaluation-order" :height="350" :adaptive="true">
      <div
        class="p-5"
        style="height: inherit; display: flex; flex-direction: column"
      >
        <div class="flex justify-end">
          <button @click.prevent="closeModalEvaluation">❌</button>
        </div>

        <div class="py-2">
          <h4 class="text-gray-700">Avaliar seu pedido</h4>
          <div class="flex space-x-3 items-center">
            <StarRating
              :increment="0.5"
              :star-size="16"
              v-model="evaluation.stars"
              text-class=" text-sm text-gray-700 mt-1"
            />
          </div>
        </div>

        <div class="py-8" style="flex-grow: 1">
          <textarea
            class="w-full h-full resize-none"
            name="comment"
            v-model="evaluation.comment"
            cols="30"
            rows="3"
            placeholder="Comentario (Opcional)"
          ></textarea>
        </div>
        <footer class="flex justify-end">
          <button
            class="bg-green-300 py-2 px-5 rounded text-white"
            @click.prevent="sendEvalution"
            :disabled="loadSendEvaluation"
          >
            <span v-if="loadSendEvaluation" class="flex items-center">
              <img
                src="@/assets/images/preloader.gif"
                alt=""
                class="w-5 h-5 mr-2"
              />
              Enviando...
            </span>
            <span v-else>Avaliar</span>
          </button>
        </footer>
      </div>
    </modal>

    <Loading :open="loading" :msg="msgLoading" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StarRating from "vue-star-rating";
import Loading from "../../components/Loading";
import LoadingOrderStatus from "../../components/LoadingOrderStatus";

export default {
  components: {
    StarRating,
    Loading,
    LoadingOrderStatus,
  },
  props: ["identify"],

  created() {
    this.loading = true;

    this.getOrderIdentify(this.identify)
      .then((response) => {
        this.loading = false;
        this.order = Object.assign(this.order, response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "error",
            title: "Não existe esse pedido",
          });

          return;
        }
      });
  },

  data() {
    return {
      loading: false,
      msgLoading: "Estamos buscando o pedido",

      order: {
        identify: "",
        date: "",
        status: "",
        subTotal: "",
        deliveryFee: "",
        total: "",
        referencePoint: "",
        deliveryPlace: "", // local de entrega
        table: {
          identify: "",
          description: "",
        },
        client: {
          name: "",
          email: "",
        },
        company: {
          name: "",
          logo: "",
        },
        evaluations: null,
        products: [],
      },

      evaluation: {
        stars: 1,
        comment: "",
      },

      loadSendEvaluation: false,
    };
  },

  methods: {
    ...mapActions(["getOrderIdentify", "evaluationOrder"]),

    openModalEvaluation() {
      this.$modal.show("evaluation-order");
    },
    closeModalEvaluation() {
      this.$modal.hide("evaluation-order");
    },
    sendEvalution() {
      this.loadSendEvaluation = true;
      let params = {
        identify: this.identify,
        ...this.evaluation,
      };
      this.evaluationOrder(params)
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "success",
            title: "Avaliação enviada com sucesso",
          });

          this.order.evaluations = response.data.data;
          this.closeModalEvaluation();
        })
        .catch((error) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "error",
            title: "Erro enviar avaliação.",
          });
        })
        .finally(() => (this.loadSendEvaluation = false));
    },
    priceFormat(value) {
      const price = value / 100;
      return price.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    generateTotalItemPrice(item) {
      let priceProd = item.qty * item.price;
      let totalPriceComplements = this.totalComplements(item);
      return priceProd + totalPriceComplements;
    },
    totalComplements(item) {
      let total = 0;

      item.complements.map((ele) => {
        total =
          total +
          ele.optionals.reduce((acc, option) => {
            acc += option.qtd * option.price;
            return acc;
          }, 0);
      });

      return total;
    },
  },

  computed: {
    ...mapState({
      me: (state) => state.auth.me,
    }),
  },
};
</script>
